<template>
    <GlobalLoading v-if="search.data.loading" />

    <form v-else @submit.prevent="getSearchResults" class="c-search-bar" @click.stop>
        <div class="c-search-bar__title" v-if="title">
            {{ title }}
        </div>

        <div class="c-search-bar__fields">
            <div class="c-search-bar__field-outer">
                <div
                    @click="scrollTo"
                    :class="{ 'is-active': currentlyAmending == 'accommodation' }"
                    @click.stop="amendSearchField('accommodation')"
                    class="c-search-bar__field">
                    <div class="c-search-bar__field-icon">
                        <Home />
                    </div>

                    <span v-if="search.data.selected_child_filters.length > 0">
                        {{
                            search.data.selected_child_filters.length > 1
                                ? `+${search.data.selected_child_filters.length} selected`
                                : search.data.filter_data.filter(
                                      (filter) => filter.id == search.data.selected_child_filters[0],
                                  )[0].name
                        }}
                    </span>

                    <span v-else> Accommodation </span>
                </div>

                <div class="c-search-bar__amend" v-if="currentlyAmending == 'accommodation'">
                    <SearchBarAmendDropdown :currentlyAmending="`accommodation`" />
                </div>
            </div>

            <div class="c-search-bar__field-outer">
                <div
                    :class="{ 'is-active': currentlyAmending == 'park', 'is-disabled': !search.can_user_select_parks }"
                    @click.stop="amendSearchField('park')"
                    class="c-search-bar__field">
                    <div class="c-search-bar__field-icon">
                        <Pin />
                    </div>

                    <span v-if="search.data.selected_parks.length > 0">
                        {{
                            search.data.selected_parks.length > 0
                                ? `+${search.data.selected_parks.length} selected`
                                : search.data.selected_parks[0].title
                        }}
                    </span>

                    <span v-else> Location </span>
                </div>

                <div class="c-search-bar__amend" v-if="currentlyAmending == 'park' && search.can_user_select_parks">
                    <SearchBarAmendDropdown :currentlyAmending="`park`" />
                </div>
            </div>

            <div class="c-search-bar__field-outer">
                <div
                    @click="scrollTo"
                    :class="{
                        'is-active': currentlyAmending == 'duration',
                        'is-disabled': !search.can_user_select_dates,
                    }"
                    @click.stop="amendSearchField('duration')"
                    class="c-search-bar__field">
                    <div class="c-search-bar__field-icon">
                        <Calendar />
                        
                    </div>

                    <span v-if="search.data.selected_date[0] && search.data.selected_nights">
                        {{ moment(search.data.selected_date[0]).format("MMM Do YYYY") }},
                        {{ search.data.selected_nights }} nights
                    </span>

                    <span v-else> Dates </span>
                </div>

                <div class="c-search-bar__amend" v-if="currentlyAmending == 'duration' && search.can_user_select_dates">
                    <SearchBarAmendDropdown v-model:currentlyAmending="currentlyAmending" />
                </div>
            </div>

            <div class="c-search-bar__field-outer">
                <div
                    :class="{
                        'is-active': currentlyAmending == 'guests',
                        'is-disabled': !search.can_user_select_dates,
                    }"
                    @click.stop="amendSearchField('guests')"
                    class="c-search-bar__field">
                    <div class="c-search-bar__field-icon">
                        <Users />
                    </div>

                    <span v-if="search.guest_count > 0 || search.pet_count > 0">
                        {{ search.guest_count }} guest{{ search.guest_count > 1 ? "s" : ""
                        }}{{
                            search.pet_count > 0 ? `, ${search.pet_count} pet${search.pet_count > 1 ? "s" : ""}` : ""
                        }}
                    </span>

                    <span v-else> Guests </span>
                </div>

                <div class="c-search-bar__amend" v-if="currentlyAmending == 'guests' && search.can_user_select_dates">
                    <SearchBarAmendDropdown :currentlyAmending="`guests`" />
                </div>
            </div>

            <div class="c-search-bar__field-outer">
                <div
                    :class="{ 'is-disabled': !search.can_user_select_dates }"
                    @click.stop="amendSearchField('promo-code')"
                    class="c-search-bar__field is-promo">
                    <div
                        class="c-search-bar__promo-input-container"
                        :class="{ 'is-focused': currentlyAmending == 'promo-code' }">
                        <div class="c-search-bar__field-icon">
                            <Promo />
                        </div>

                        <span v-if="currentlyAmending == 'promo-code'">
                            <input
                                v-model="search.data.promo_code"
                                class="c-search-bar__promo-code-input"
                                type="text"
                                placeholder="Enter your code"
                                ref="promoCodeInput" />
                        </span>

                        <div
                            class="c-search-bar__close-promo"
                            @click.stop="amendSearchField(null)"
                            v-if="currentlyAmending == 'promo-code'">
                            <i class="fa fa-times"></i>
                        </div>

                        <span v-if="currentlyAmending != 'promo-code' && !search.data.promo_code">
                            Have a promo code?
                        </span>

                        <span v-if="currentlyAmending != 'promo-code' && search.data.promo_code">
                            {{ search.data.promo_code }}
                        </span>
                    </div>
                </div>
            </div>

            <button
                :disabled="!search.can_user_search || search.data.searching"
                :class="{ disabled: !search.can_user_search }"
                type="submit"
                class="c-search-bar__button">
                {{ search.data.searching ? "Searching..." : "Search" }}
            </button>
        </div>
    </form>
</template>

<script setup>
    import Home from "~/assets/icons/home.svg";
    import Pin from "~/assets/icons/pin.svg";
    import Calendar from "~/assets/icons/calendar.svg";
    import Users from "~/assets/icons/users.svg";
    import Promo from "~/assets/icons/promo.svg";
    import moment from "moment";

    const emit = defineEmits(["search"]);

    const bookingStore = useBookingStore();

    const search = useSearchStore();
    const currentlyAmending = ref(search.data.currently_amending || null);
    const router = useRouter();
    const promoCodeInput = ref(null);

    const props = defineProps({
        title: {
            type: String,
            required: false,
        },
    });

    const scrollTo = async () => {
        const el = document.getElementById("search");
        if (el) {
            el.scrollIntoView({ behavior: "smooth" });
        }
    };

    const amendSearchField = async (type = null) => {
        if (
            (type == "parks" || type == "duration" || type == "guests" || type == "promo-code") &&
            (!search.can_user_select_dates || !search.can_user_select_parks)
        ) {
            return;
        }

        if (type === "promo-code") {
            currentlyAmending.value = type;

            await nextTick();

            if (promoCodeInput.value) {
                promoCodeInput.value.focus();
            }
        } else if (type == null && currentlyAmending.value === "promo-code") {
            search.data.promo_code = "";
        }

        if (currentlyAmending.value && currentlyAmending.value == type) {
            // currentlyAmending.value = null;
            return;
        }

        currentlyAmending.value = type;
    };

    const getSearchResults = () => {
        if (!search.can_user_search) {
            alert(search.errors[0].message);
            return;
        }

        currentlyAmending.value = null;
        bookingStore.order.guest_session = null;

        router.push({
            path: "/search/results",
        });

        emit("search");
    };

    onMounted(async () => {
        if (search.data.selected_flexible_dates === 28) {
            search.data.selected_flexible_dates = 0;
        }
        if ("body" in document) {
            document.body.addEventListener("click", () => {
                currentlyAmending.value = null;
            });

            document.body.addEventListener("keydown", (e) => {
                if (e.key == "Escape") {
                    currentlyAmending.value = null;
                }
            });

            await search.fetchParkData();
            await search.fetchProductWPData();
            await search.fetchFilterData();

            search.loadingComplete();
        }
    });
</script>
